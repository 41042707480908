export enum BuildersListKey {
  listBatchesTest = 'list_batches_test',
  listShipmentBatches = 'list_shipment_batches',
  listUnplannedBatches = 'list_unplanned_batches',
  listPlannedBatches = 'list_planned_batches',
  listLots = 'list_lots',
  listCombineLots = 'list_combine_lots',
  listConfirmLots = 'list_confirm_lots',
  listUnmatchedLots = 'list_unmatched_lots',
  listOpenShipments = 'list_open_shipments',
  listSettledShipments = 'list_settled_shipments',
  listFinanceLots = 'list_finance_lots',
  listAirportDeliveryLag = 'airport-delivery-lag',
  listFulfillmentShipment = 'list_fulfillment_shipment',
  listPrinters = 'printer',
  listProductFile = 'list_product-file',
  listJournals = 'list_journals',
  listFloridayErrors = 'list_floriday_error_log',
  listFulfillmentLots = 'list_fulfillment_lots',
  listSalesOrders = 'list_sales_orders',
  listMailing = 'mailing-list',
  listForecast = 'list_forecast',
  listInventoryChecks = 'list_inventory_checks',
  listStockDeliveries = 'list_stock_deliveries',
  listHistory = 'list_history',
  listFinanceSalesOrders = 'list_finance_sales_orders',
  listFarmPacking = 'farm-packing',
  listProductFreshness = 'list_product_freshness',
  listLotsSalesOrders = 'list_lots_sales_orders',
  listFinanceLotsOverview = 'list_finance_lots_overview',
  listMobileConfirmLots = 'list_mobile_confirm_lots',
  listMobileCombineLots = 'list_mobile_combine_lots',
  listFieldRejections = 'list_field_rejections',
}

export enum BuildersFormKey {
  moveToProductionLine = 'form_move-to-production-line',
  matchToShipment = 'form_match_to_shipment',
  confirmReceptionStock = 'form_confirm_reception_stock',
  stockOrder = 'form_stock_order',
}
