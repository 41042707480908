export enum BuilderActionType {
  itemDelete = 'delete',
  itemDownload = 'backend_s3_download',
  closePopup = 'close_popup',
  bulkDownload = 'bulk_download',
  backendConfirmation = 'backend_confirmation',
  backend = 'backend',
  refresh = 'refresh',
  selectRow = 'select_row',
}
