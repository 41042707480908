import { Component, inject, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ModelLog } from '@core/models/model-log.model';
import { FieldRejectionService } from '@core/api/field-rejection.service';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-field-rejection-history-logs',
  templateUrl: './field-rejection-history-logs.component.html',
  standalone: true,
  imports: [SharedModule],
})
export class FieldRejectionHistoryLogsComponent implements OnInit {
  public changes: { [key: string]: ModelLog[] };

  private config = inject(DynamicDialogConfig);
  private fieldRejectionService = inject(FieldRejectionService);

  public ngOnInit(): void {
    const rejectionId = this.config.data.item.id;

    this.fieldRejectionService.get(rejectionId, { include: ['modelLogs'] }).subscribe({
      next: (fieldRejection) => {
        this.changes = fieldRejection.history;
      },
    });
  }
}
