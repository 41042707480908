import { APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxPermissionsGuard, NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AuthGuard, AuthModule, AuthService } from '@capturum/auth';
import { ApiModule, DateTimezoneInterceptor } from '@capturum/api';
import { LoginModule } from '@capturum/login';
import { AppRoutes } from '@core/enums/routes.enum';
import { BootHelper } from '@core/utils/boot.helper';
import { environment } from '@environments/environment';
import { IndexDbObservation } from '@core/indexDb/model/indexDb-observation.model';
import { IndexDbHarvest } from '@core/indexDb/model/indexDb-harvest.model';
import { ErrorNotifyInterceptor } from '@core/interceptors/error-notify.interceptor';
import { DxpBaseListComponent } from '@core/base/dxp-base-list.class';
import { API_DATE_BLACKLIST, API_DATE_PROPERTIES, DatesFormats } from '@core/configs/date-config';
import { IndexDbSyncError } from '@core/indexDb/model/indexDb-sync-error.model';
import { LoginService } from '@core/services/login.service';
import { ManageHttpInterceptor } from '@core/interceptors/manage-http.interceptor';
import { CustomHeadersInterceptor } from '@core/interceptors/custom-header.interceptor';
import { MarginparTranslationKeyService } from '@core/api/translation-key.service';
import { DxpBaseDetailComponent } from '@core/base/base-detail.class';
import { DxpBaseEntityComponent } from '@core/base/base-entity.class';
import { BaseFormComponent } from '@core/base/base-form.class';
import { DxpIndexablePermissionService } from '@core/indexDb/services/dxp-indexable-permission.service';
import { DxpIndexableBaseDataKeyService } from '@core/indexDb/services/dxp-indexable-base-data-key.service';
import { DxpIndexableBaseDataService } from '@core/indexDb/services/dxp-indexable-base-data.service';
import { DxpIndexableBaseDataValueService } from '@core/indexDb/services/dxp-indexable-base-data-value.service';
import { DxpIndexableModuleService } from '@core/indexDb/services/dxp-indexable-module.service';
import { DxpIndexableSettingService } from '@core/indexDb/services/dxp-indexable-setting.service';
import { PopupFormComponent } from '@core/base/popup-form.class';
import { BaseUploadComponent } from '@core/base/base-upload.class';
import { CompleteConfigInterface, CompleteModule } from '@capturum/complete';
import { SettingListComponent } from '../features/admin/setting/pages/setting-list/setting-list.component';
import { TranslationListComponent } from '../features/admin/translation/pages/translation-list/translation-list.component';
import { BaseDataListComponent } from '../features/admin/base-data/pages/base-data-list/base-data-list.component';
import { CapturumListRendererModule } from '@capturum/builders/list-renderer';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';
import { ListDeleteService } from '@core/actions/list-delete.service';
import { ListDownloadService } from '@core/actions/list-download.service';
import { DialogCloseService } from '@core/actions/dialog-close.service';
import { ListBackendConfirmationService } from '@core/actions/list-backend-confirmation.service';
import { CombineLotsComponent } from '../features/admin/fulfillment/components/combine-lots/combine-lots.component';
import { ListRendererWidgetInputComponent } from '@core/builders/list-renderer-widget-input/list-renderer-widget-input.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { ReactiveFormsModule } from '@angular/forms';
import { EditLotCharacteristicsPopupComponent } from '../features/admin/fulfillment/components/edit-lot-characteristics-popup/edit-lot-characteristics-popup.component';
import { ConfirmLotsComponent } from '../features/admin/fulfillment/components/confirm-lots/confirm-lots.component';
import { MatchToShipmentComponent } from '../features/admin/fulfillment/components/match-to-shipment/match-to-shipment.component';
import { FlightsComponent } from '@shared/components/flights/flights.component';
import { DownloadLogisticLabelPopupComponent } from '../features/admin/fulfillment/components/download-logistic-label-popup/download-logistic-label-popup.component';
import { ListRendererWidgetImageComponent } from '@core/builders/list-renderer-widget-image/list-renderer-widget-image.component';
import { ListRendererWidgetNumberComponent } from '@core/builders/list-renderer-widget-number/list-renderer-widget-number.component';
import { ListRendererWidgetNumberWithoutDecimalsComponent } from '@core/builders/list-renderer-widget-number-without-decimals/list-renderer-widget-number-without-decimals.component';
import { LotProductComponent } from '@core/builders/lot-product/lot-product.component';
import { AddLotPopupComponent } from '../features/admin/fulfillment/components/add-lot-popup/add-lot-popup.component';
import { BackendService } from '@core/actions/backend.service';
import { NgxsModule } from '@ngxs/store';
import { SentryErrorHandler } from '@core/error-handlers/SentryErrorHandler';
import { ListRendererWidgetCheckboxComponent } from '@core/builders/list-renderer-widget-checkbox/list-renderer-widget-checkbox.component';
import { SharedModule } from '@shared/shared.module';
import { CapturumInputNumberModule } from '@capturum/ui/input-number';
import { ListRendererWidgetInputNumberWithButtonsComponent } from '@core/builders/list-renderer-widget-input-number-with-buttons/list-renderer-widget-input-number-with-buttons.component';
import { ReorderLotsWidgetComponent } from '../features/mobile/mobile-fulfillment/mobile-confirm-lots/pages/reorder-lots-widget/reorder-lots-widget.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { CheckboxInputBuilder } from '../features/builders/input-builders/checkbox-input.builder';
import { MoveToProductionLineComponent } from '../features/admin/fulfillment/components/move-to-production-line/move-to-production-line.component';
import { CommentComponent } from '../features/admin/package-management/components/comment/comment.component';
import { DifferenceComponent } from '../features/admin/package-management/components/difference/difference.component';
import { ForecastPackageTypeDataComponent } from '../features/admin/package-management/components/forecast-package-type-data/forecast-package-type-data.component';
import { IsLockedComponent } from '../features/admin/auction-sales/components/is-locked/is-locked.component';
import { HistoryLogsComponent } from '../features/admin/fulfillment/components/history-logs/history-logs.component';
import { ConfirmReceptionComponent } from '../features/admin/package-management/components/confirm-reception/confirm-reception.component';
import { StockOrderFormComponent } from '../features/admin/package-management/components/stock-order-form/stock-order-form.component';
import { InventoryCheckFormComponent } from '../features/admin/package-management/components/inventory-check-form/inventory-check-form.component';
import { AuctionOrderActionsComponent } from '../features/admin/auction-sales/components/auction-order-actions/auction-order-actions.component';
import { UnMatchSalesComponent } from '../features/admin/finance/components/un-match-sales/un-match-sales.component';
import { SplitShipmentsPopupComponent } from '../features/admin/fulfillment/components/split-shipments-popup/split-shipments-popup.component';
import { CancelLotComponent } from '@shared/components/cancel-lot/cancel-lot.component';
import { ListenOnRowClickAction } from '@core/actions/listen-on-row-click.action';
import { FieldRejectionHistoryLogsComponent } from '../features/admin/field-rejection/components/field-rejection-history-logs/field-rejection-history-logs.component';

export const MARGINPAR_CORE_CONFIG: CompleteConfigInterface = {
  indexedDbModels: [IndexDbObservation, IndexDbHarvest, IndexDbSyncError],
  databaseName: environment.databaseName,
  version: 2,
  userSpecificDb: false,
  configPage: {
    route: '/admin/manage',
    except: [SettingListComponent.name, TranslationListComponent.name, BaseDataListComponent.name],
  },
  permissionService: DxpIndexablePermissionService,
  baseDataService: DxpIndexableBaseDataService,
  baseDataValueService: DxpIndexableBaseDataValueService,
  baseDataKeyService: DxpIndexableBaseDataKeyService,
  translateKeyService: MarginparTranslationKeyService,
  moduleService: DxpIndexableModuleService,
  settingService: DxpIndexableSettingService,
  environment,
};

const BASE_WIDGETS = [
  ListRendererWidgetInputComponent,
  ListRendererWidgetImageComponent,
  ListRendererWidgetNumberComponent,
  ListRendererWidgetNumberWithoutDecimalsComponent,
  ListRendererWidgetCheckboxComponent,
  LotProductComponent,
  ListRendererWidgetInputNumberWithButtonsComponent,
  ReorderLotsWidgetComponent,
];

@NgModule({
  declarations: [
    DxpBaseEntityComponent,
    DxpBaseListComponent,
    DxpBaseDetailComponent,
    BaseUploadComponent,
    BaseFormComponent,
    PopupFormComponent,
    ...BASE_WIDGETS,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: `/${AppRoutes.auth}/${AppRoutes.login}`,
      unauthenticatedRejectionRoute: `/${AppRoutes.admin}/${AppRoutes.dashboard}`,
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError: BootHelper.onAuthError,
      dateInterceptor: {
        dateProperties: API_DATE_PROPERTIES,
        blackListedURLs: API_DATE_BLACKLIST,
        blackListedStatusCodes: [401, 204],
        displayFormat: DatesFormats.displayFormatDateFns,
        sendFormat: DatesFormats.sendFormatDateFns,
      },
    }),
    CompleteModule.forRoot(MARGINPAR_CORE_CONFIG, NgxPermissionsService, NgxPermissionsModule),
    LoginModule.forRoot({
      environment,
      defaultLanguage: 'en',
      productName: environment.appName,
      redirectAfterLogin: environment.redirectAfterLogin,
      loginService: LoginService,
      displayPassword: {
        autoCloseTimeout: 2500,
      },
      displayInputsLabel: false,
    }),
    NgxsModule.forRoot(),
    CapturumListRendererModule.forRoot({
      widgets: {
        input: ListRendererWidgetInputComponent,
        flights: FlightsComponent,
        image: ListRendererWidgetImageComponent,
        number: ListRendererWidgetNumberComponent,
        checkbox: ListRendererWidgetCheckboxComponent,
        numberWithoutDecimals: ListRendererWidgetNumberWithoutDecimalsComponent,
        lotProduct: LotProductComponent,
        comment: CommentComponent,
        difference: DifferenceComponent,
        forecastPackageType: ForecastPackageTypeDataComponent,
        isLocked: IsLockedComponent,
        inputNumberWithButtons: ListRendererWidgetInputNumberWithButtonsComponent,
        reorder: ReorderLotsWidgetComponent,
      },
      list_product_freshness: {
        actions: {
          lock: {
            hiddenExpression: (row: any) => {
              return row.is_locked;
            },
          },
          unlock: {
            hiddenExpression: (row: any) => {
              return !row.is_locked;
            },
          },
        },
      },
    }),
    CapturumFormRendererModule.forRoot({
      translationKeyPrefix: 'dxp',
      builders: {
        checkbox: CheckboxInputBuilder,
      },
    }),
    CapturumBuilderCoreModule.forRoot({
      components: {
        list_planned_batches_move_to_production_line: MoveToProductionLineComponent,
        list_planned_batches_bulk_move_to_production_line: MoveToProductionLineComponent,
        list_unplanned_batches_plan: MoveToProductionLineComponent,
        list_unplanned_batches_bulk_plan: MoveToProductionLineComponent,
        list_lots_bulk_combine_lots: CombineLotsComponent,
        list_lots_edit_lot_characteristics: EditLotCharacteristicsPopupComponent,
        list_lots_bulk_confirm_lots: ConfirmLotsComponent,
        list_lots_confirm_lot: ConfirmLotsComponent,
        list_lots_history: HistoryLogsComponent,
        list_lots_download_logistics_label: DownloadLogisticLabelPopupComponent,
        list_lots_cancel_lot: CancelLotComponent,
        list_finance_lots_history: HistoryLogsComponent,
        list_finance_lots_un_match: UnMatchSalesComponent,
        list_finance_lots_overview_cancel_lot: CancelLotComponent,
        list_finance_lots_cancel_lot: CancelLotComponent,
        list_unmatched_lots_match_to_shipment: MatchToShipmentComponent,
        list_shipment_batches_add_lot: AddLotPopupComponent,
        list_shipment_batches_bulk_split_shipments: SplitShipmentsPopupComponent,
        list_stock_deliveries_confirm_reception: ConfirmReceptionComponent,
        list_stock_deliveries_edit_stock_order: StockOrderFormComponent,
        list_inventory_checks_edit: InventoryCheckFormComponent,
        list_fulfillment_lots_history: HistoryLogsComponent,
        list_fulfillment_lots_bulk_combine_lots: CombineLotsComponent,
        list_fulfillment_lots_bulk_confirm_lots: ConfirmLotsComponent,
        list_fulfillment_lots_confirm_lot: ConfirmLotsComponent,
        list_fulfillment_lots_edit_lot_characteristics: EditLotCharacteristicsPopupComponent,
        list_fulfillment_lots_download_logistics_label: DownloadLogisticLabelPopupComponent,
        list_fulfillment_lots_cancel_lot: CancelLotComponent,
        list_product_freshness_split_date: AuctionOrderActionsComponent,
        list_product_freshness_split_channels: AuctionOrderActionsComponent,
        list_product_freshness_split_stems: AuctionOrderActionsComponent,
        list_field_rejections_history: FieldRejectionHistoryLogsComponent,
      },
      actions: {
        [BuilderActionType.itemDelete]: ListDeleteService,
        [BuilderActionType.itemDownload]: ListDownloadService,
        [BuilderActionType.closePopup]: DialogCloseService,
        [BuilderActionType.backendConfirmation]: ListBackendConfirmationService,
        [BuilderActionType.backend]: BackendService,
        [BuilderActionType.selectRow]: ListenOnRowClickAction,
      },
    }),
    SharedModule,
    CapturumInputModule,
    ReactiveFormsModule,
    CapturumInputNumberModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        return () => {};
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AuthService,
    AuthGuard,
    NgxPermissionsService,
    NgxPermissionsGuard,
    DxpIndexablePermissionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorNotifyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateTimezoneInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHeadersInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    BootHelper.throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
