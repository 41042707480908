export enum Entity {
  observation = 'observation',
  harvest = 'harvest',
  harvestDeliveryNote = 'harvest-delivery-note',
  grading = 'grading',
  block = 'block',
  farm = 'farm',
  organisation = 'organisation',
  farmRole = 'farm-role',
  package = 'package',
  product = 'product',
  productGroup = 'product-group',
  productGrading = 'product-grading',
  productPacking = 'product-packing',
  productBaseRate = 'product-base-rate',
  gradingStation = 'grading-station',
  deliveryCondition = 'delivery-condition',
  shipment = 'shipment',
  user = 'user',
  address = 'address',
  contact = 'contact',
  salesChannel = 'sales-channel',
  salesContract = 'sales-contract',
  order = 'order',
  orderLine = 'order-line',
  region = 'region',
  deliveryLag = 'delivery-lag',
  manage = 'manage',
  production = 'production',
  harvestPlanning = 'harvest-planning',
  priceRate = 'price-rate',
  deliveryOrder = 'delivery-order',
  batch = 'batch',
  location = 'location',
  transport = 'transport',
  auctionSales = 'auction-sales',
  auctionStrategy = 'auction-strategy',
  auctionOrder = 'auction-order',
  marketDemand = 'market-demand',
  farmProductionBudget = 'farm-production-budget',
  baseData = 'base-data',
  villagePlanning = 'village-planning',
  tradeItem = 'trade-item',
  gradedStock = 'graded-stock',
  boxedStock = 'boxed-stock',
  stock = 'stock',
  setting = 'setting',
  role = 'role',
  translation = 'translation',
  rejectedHarvest = 'rejected-harvest',
  allocation = 'allocation',
  dashboard = 'dashboard',
  fulfillment = 'fulfillment',
  logistics = 'logistics',
  builder = 'builder',
  report = 'report',
  client = 'client',
  activeProductionLine = 'active-production-line',
  finance = 'finance',
  packingOrder = 'packing-order',
  productFile = 'product-file',
  printer = 'printer',
  packageManagement = 'package-management',
  forecastAllocation = 'forecast-allocation',
  auctionConfigDaySplit = 'auction-config-day-split',
  fieldRejection = 'field-rejection',
}

/**
 * Entities that do not use the same name in the FE and BE
 */
export enum DisplayEntity {
  sales = 'sales', // Client wants to call it sales
  users = 'users', // For some reason urls are plural
  floricodeUpload = 'floricode-upload',
  boxing = 'boxing',
  productPacking = 'product_packing',
  productFiles = 'product_files',
  printers = 'printers',
}
