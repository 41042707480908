import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { FieldRejectionBody } from '@core/models/production.model';
import { Observable } from 'rxjs';
import { FieldRejection } from '@core/enums/field-rejection.model';

@Injectable({
  providedIn: 'root',
})
export class FieldRejectionService extends ApiService<FieldRejection> {
  protected endpoint = 'field-rejection';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public rejectFields(data: FieldRejectionBody): Observable<FieldRejection> {
    return this.apiHttp.post(`/${this.endpoint}/production`, data);
  }
}
