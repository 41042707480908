import { ActionOptions, ActionProvider } from '@capturum/builders/core';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BuilderActionType } from '@core/enums/builder-action-type.enum';

@Injectable({ providedIn: 'root' })
export class ListenOnRowClickAction implements ActionProvider {
  private router = inject(Router);

  public execute(options: ActionOptions, item: any, context: Record<string, string>): void {
    if (options.type === BuilderActionType.selectRow) {
      const route = options.route.replace(':id', item?.id);

      this.router.navigateByUrl(route, {
        state: { item },
      });
    }
  }
}
