export enum ModelLoggableType {
  Production = 'production',
  Harvest = 'harvest',
  HarvestPlanning = 'harvest-planning',
  HarvestPlanningGradeSplit = 'harvest-planning-grade-split',
  Product = 'product',
  OrderLine = 'order-line',
  PriceRate = 'price-rate',
  Shipment = 'packing-order', // We need to overwrite the entity name do not change the translation keys, requested by client
  HarvestDeliveryNote = 'harvest-delivery-note',
  GradingStation = 'grading-station',
  GradedStock = 'graded-stock',
  ProductPacking = 'product-packing',
  ShipmentInformation = 'packingorderinformation', // We need to overwrite the entity name do not change the translation keys, requested by client
  ShippingSchedule = 'shipping-schedule',
  LotFloricodeFeature = 'lot-floricode-feature',
  FieldRejection = 'field-rejection',
}
