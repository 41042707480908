export enum BaseKey {
  color = 'color',
  harvestPlanningChangeReason = 'harvest_planning_change_reason',
  genus = 'genus',
  organisationType = 'organisation_type',
  addressType = 'address_type',
  productionObservationType = 'production_observation_types',
  rejectionReasons = 'rejection_reasons',
  packageType = 'package_type',
  tradeLane = 'trade_lanes',
  countryCode = 'country_code',
  currencyCode = 'currency_code',
  orderType = 'order_type',
  paymentMethod = 'payment_method',
  orderStatus = 'order_status',
  allocationStatus = 'allocation_status',
  packingOrderStatus = 'packing_order_status',
  incoterm = 'incoterm',
  contactType = 'contact_type',
  priceRateType = 'price_rate_type',
  homePage = 'homepage',
  packingOrderInformation = 'packing_order_information',
  locationType = 'location_type',
  printerLocation = 'printer_location',
  planted = 'production_planted',
  printerType = 'printer_type',
  stage = 'production_stage',
  plantForm = 'production_plant_form',
  plantGrowingType = 'plant_growing_type',
  villageRole = 'village_role',
  adjustingProcess = 'adjusting_process',
  productFileType = 'product_file_type',
  shipmentStatus = 'shipmentStatus',
  logisticStatus = 'logisticStatus',
  finalDestination = 'final_destination',
  transportType = 'transport_type',
  destination = 'airport_code',
  carrier = 'carrier_code',
  productionLine = 'production_line',
  floricodeFeatureGroups = 'floricode_feature_groups',
  flightStatus = 'flight_status',
  fulfillmentRejectionReasons = 'fulfillment_rejection_reasons',
  lotCancelReasons = 'lot_cancel_reason',
  fieldRejectionReason = 'field_rejection_reason',
}
